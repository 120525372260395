<template>
  <div>
    <div>
      若已登入微軟帳戶請登出並切換到以下帳戶，微軟登入帳戶：developer@gping.net，密碼請洽技術
    </div>
    <div>
      要共享報表必須付費使用，每個帳戶費用一年3600，現階段無預算
    </div>
    <div>
      因使用同一帳戶會有安全性因素，故資料為不定時更新，需要更新請聯絡技術
    </div>
    <div class="text-danger">
      Q1.為何會員數與後台不同?
    </div>
    <div>
      A.計算基礎不同，後台是單純計算註冊數，且在後台註冊的會員因線上計算複雜度高會導致負載很重，只能都歸類為吉品購物會員
    </div>
    <div>
      而報表的會員數是看以下基準，1.在哪裡註冊、2.在哪裡消費，所以註冊在A站也會因為在B站消費過而算成B站會員
    </div>
    <div class="text-danger">
      Q2.為何銷售數字與後台不同?
    </div>
    <div>
      A.部分舊訂單與目前的程式邏輯不同無法納入，且部分商品編號在2017年有改過也無法納入計算
    </div>
    <div>
      另外，因為是非即時統計資料，最新1個月的訂單資料仍有可能因客戶取消而變動
    </div>
    <div class="text-danger">
      Q3.為何年齡段50-59歲特別多?
    </div>
    <div>
      A.原因1：2019開始客人不需要填資料，只需認證手機就能快速註冊，原因2：系統建立的預設生日就是1970/01/01
    </div>
    <div class="text-danger">
      Q4.為何福貴糕沒有2019年中秋前的銷售資料卻有會員?
    </div>
    <div>
      A.之前訂單是統一匯入在吉品系統，網站獨立後才分開計算
    </div>
    <hr>
    <div>
      統計報表，請點連結
    </div>
    <ul>
      <li
        v-for="item in statistics_data"
        :key="item.code"
      >
        <router-link
          :to="item.link"
          target="_blank"
          class="text-color_primary"
        >
          {{ item.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      now_page: 'statistics',
    };
  },
  computed: {
    ...mapGetters('system', ['system', 'option']),
    statistics_data: {
      get() {
        const vm = this;
        const data = vm.option.powerBIReport;
        console.log(data);
        return data;
      },
    },
  },
  async mounted() {
    const vm = this;
    await vm.$store.dispatch('page_load', true);
    await vm.$store.dispatch('now_page', vm.now_page);
    await vm.get_system();
    await vm.get_system_option();
    await vm.$store.dispatch('page_load', false);
  },
  methods: {
    ...mapActions('system', ['get_system', 'get_system_option']),
  },
};
</script>
